<template>
  <div class="author">
    负责人：晁凯
    <br />邮箱：chaokai@tom.com
    <br />
    <br />
    
    <el-button class="btn" @click="back">返回首页</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
.author {
  padding: 20px;
  color: #333;
}
</style>